/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateLocum } from '../../../redux/slices/UserSlice';
import { FacilityCurrentResult } from './components/FacilityCurrentResult';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const MyFacilitiesPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const handleSetLocumClick = async () => {
        try {
            setErrMsg(null);
            setLoading(true);
            const response = await dispatch(updateLocum(!user.facilities.isLocum));
            if (response) {
                if (!response.payload?.success) {
                    if (response.payload?.message) {
                        setErrMsg(response.payload.message);
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                }
            } else {
                setErrMsg('An error has occured, please try again later');
            }
            setLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrMsg(err.message);
            } else {
                setErrMsg('An error has occurred, please try again later');
            }
            setLoading(false);
        }
    };

    const GeneratePrimaryFacility = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>Primary Practice Facility</h3>
                </div>

                <div className='card-body pt-0'>
                    {user.facilities.primary.name ? (
                        <FacilityCurrentResult
                            facility={user.facilities.primary}
                            modalName='kt_modal_remove_facility_primary'
                        />
                    ) : (
                        <div>
                            You do not currently have a primary facility. Please add a facility using the{' '}
                            <Link className='text-decoration-underline fw-bold' to={'/facilities/search'}>
                                Facility Search
                            </Link>{' '}
                            or select 'Set as primary' on one of your current facilities listed below.
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const GenerateOtherListedFacilities = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>Other Listed Practice Facilities</h3>
                </div>

                <div className='card-body pt-0'>
                    {user.facilities.other.length === 0 ? (
                        <div>
                            You do not have any other facilities. Please add a facility using the{' '}
                            <Link className='text-decoration-underline fw-bold' to={'/facilities/search'}>
                                Facility Search
                            </Link>
                            .
                        </div>
                    ) : (
                        user.facilities.other.map((facility, i) => {
                            return (
                                <FacilityCurrentResult
                                    key={i}
                                    facility={facility}
                                    modalName={`kt_modal_remove_facility_${i}`}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        <li className='mb-5'>
                            Below should be a complete list of all practice facilities where you currently work – full
                            or part-time (including regular/recurring locum shifts).
                        </li>
                        <li className='mb-5'>
                            If the list below is not accurate or complete, please update our records by removing or
                            adding facilities as necessary. To add a facility to your record, please search for it using
                            the{' '}
                            <Link className='text-decoration-underline fw-bold' to={'/facilities/search'}>
                                Facility Search
                            </Link>
                            .
                        </li>
                        <li className='mb-5'>
                            If your veterinary work is in an environment other than a veterinary practice facility (eg.
                            industry, government, etc), please contact the office at{' '}
                            <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                reception@cvbc.ca
                            </a>{' '}
                            to update your work information. Please note that the Facility Search only includes
                            accredited practice facilities.
                        </li>
                        <li className='mb-5'>
                            Please update our records to indicate whether or not you are currently working as a locum.
                            <br/>
                            CVBC records indicate: You{' '}
                            <span className='fw-bolder'>are {!user.facilities.isLocum && 'not'} </span> a Locum. To change
                            your locum status,{' '}
                            <span className='btn-link link-primary cursor-pointer hover'>
                                {!loading && (
                                    <span
                                        className='text-decoration-underline fw-bold'
                                        onClick={() => handleSetLocumClick()}
                                    >
                                        please click here.
                                    </span>
                                )}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'inline-block' }}>
                                        Switching locum status, please wait ...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </span>{' '}
                            {errMsg && <div className='text-danger'>{errMsg}</div>}
                        </li>
                        <li className='mb-5'>
                            <b>Attention Designated Registrants: </b><br/>
                            <ul>
                                <li className='mb-5'>
                                    CVBC bylaw 3.2 mandates an online registry of all accredited practice facilities be maintained by the Registrar. For the Facility Registry, publication of the <b>street address is optional</b> in recognition that some practice facilities are based out of private residences. The default setting is to not list the street address unless given permission by the DR to do so. Please click on the <b>Edit Details</b> option for your practice facility below to check and modify your publication permissions.
                                </li>
                                <li className='mb-5'>
                                    <b>Please note:</b> Not all information required by veterinary distributors for shipment of supplies and pharmaceuticals to practice facilities is available on the public online registry. To assist practice facilities, CVBC shares information regarding facility address, designated registrant information, and confirms the facility's accreditation status with AVP, Summit, WDDC and CDMV. Please advise the CVBC at facilities@cvbc.ca if you do not consent to the CVBC sharing this information with veterinary distributors.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            {GeneratePrimaryFacility()}
            {GenerateOtherListedFacilities()}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* ---------------------------- COMPONENT WRAPPER --------------------------- */

const MyFacilitiesWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.MYFACILITIES' })}</PageTitle>
            <MyFacilitiesPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { MyFacilitiesWrapper };

/* -------------------------------------------------------------------------- */
