/* --------------------------------- IMPORTS -------------------------------- */
import { FC, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updatePermission } from '../../../redux/slices/UserSlice';
import { Link } from 'react-router-dom';

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const schema = Yup.object().shape({
    pfn_onlinepublication: Yup.string().required('Online publication is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ChangePermission: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const initialValues = {
        pfn_onlinepublication: user.permission.formatted ? user.permission.formatted : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                setSuccessfullySubmitted(false);
                const response = await dispatch(updatePermission(values.pfn_onlinepublication));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        setSuccessfullySubmitted(true);
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form className='form w-100' onSubmit={formik.handleSubmit} id='edit_phone_form'>
                <div className='mb-10'>
                    <div className='mb-5'>
                        The BC <em> Veterinarians Act </em>(section 41) establishes the requirements and restrictions for publication of registrant information in the CVBC's Online Registry, including the <b>requirement for the consent from the registrant to include information about the registrant's place of practice/work</b> (publication of the business name, business address and business telephone number). This requirement is in consideration that some registrants operate their accredited practice facility out of their home, and/or that the nature of a registrant's work/practice may make it inappropriate/unnecessary for their work information to be directly accessible to the public. <b>The CVBC therefore offers 3 options for publication - the default setting (when permission has not been expressly provided) is to not publish any of the registrant's business information.</b> This means that no information about the registrant's place of business will be published with their listing in the Veterinarian Online Registry, nor will their name be included in the list of veterinarians practising at the practice facility within the Facility Online Registry. <b>Please be aware that, without permission to publish practice information, the CVBC is also denied permission to disclose such details directly to third parties upon request</b>, such as wholesalers and pharmacies, which may hamper your dealings with them.
                    </div>
                </div>
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark mb-5'>
                    To update your publication permissions, please select your preferred option from the following list:
                    </label>
                    <select
                        {...formik.getFieldProps('pfn_onlinepublication')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication,
                        })}
                        name='pfn_onlinepublication'
                    >
                        <option disabled value=''>
                            Select Permission Type
                        </option>
                        <option value='All Business Info'>I wish for complete business information (business name, street address, and phone number) to be published with my entry in the Veterinarian Online Registry.</option>
                        <option value='Business Name & Phone'>I wish for my business name, city and phone number to be published with my entry in the Veterinarian Online Registry, but NOT the street address.</option>
                        <option value='None'>I do not want any of my business information published with my entry in the Veterinarian Online Registry.</option>
                    </select>
                    {formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_onlinepublication}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <Link to='/'>
                            <button className='btn btn-light me-3'>Back</button>
                        </Link>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                {successfullySubmitted && (
                    <div className='text-primary text-center mt-7'>Your permission has been successfully updated.</div>
                )}
            </form>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const ChangePermissionWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Change Permissions for Publication of Work Information</PageTitle>
            <ChangePermission />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ChangePermissionWrapper };
